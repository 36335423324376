export const PRICE = 22000;

export const GABIA_SEARCH_RESULT_CODE = {
  AVAILABLE: '32000',
  REGISTERED: '34000',
};

export const GABIA_REGISTRATION_RESULT_CODE = {
  OK: '10000',
};

export const ALERT_MESSAGE = {
  EMPTY_STRING: '',
  NO_INPUT: '도메인을 입력해 주세요.',
  INVALID_INPUT: '도메인 형식이 올바르지 않습니다.',
  INVALID_DOMAIN_NAME: '도메인 형식이 올바르지 않습니다.',
  SEARCHING: '도메인을 검색하고 있습니다. 조금만 기다려 주세요.',
  ERROR: '오류가 발생했습니다. 다시 시도해 주세요.',
  INTERNAL_SERVER_ERROR: '서버에 문제가 발생했습니다. 식스샵 고객센터로 문의해 주세요.',
  PAYMENT_OK_REGISTER_FAIL:
    '결제는 승인되었으나 주문 처리가 정상적으로 이루어지지 않았습니다. 식스샵 고객센터로 연락 바랍니다.',
  PAYMENT_FAIL: '신용카드 결제 오류. 결제 정보 확인 후 다시 입력해 주시기 바랍니다.',
  CHECK_CERTIFICATE_TYPE: '기관등록증 구분 입력란을 확인해 주세요.',
  CHECK_CERTIFICATE: '기관등록증 번호 입력란을 확인해 주세요.',
  CHECK_INSTITUTION_KOREAN_NAME: '기관이름(한글) 입력란을 확인해 주세요.',
  CHECK_INSTITUTION_ENGLISH_NAME: '기관이름(영어) 입력란을 확인해 주세요.',
  OWNER_KOREAN_NAME_NO_INPUT: '소유자명(한글)을 입력해 주세요.',
  OWNER_ENGLISH_NAME_NO_INPUT: '소유자명(영어)을 입력해 주세요.',
  MANAGER_KOREAN_NAME_NO_INPUT: '관리자명(한글)을 입력해 주세요.',
  MANAGER_ENGLISH_NAME_NO_INPUT: '관리자명(영어)을 입력해 주세요.',
  CHECK_OWNER_KOREAN_NAME: '소유자명(한글) 입력란을 확인해 주세요.',
  CHECK_OWNER_ENGLISH_NAME: '소유자명(영어) 입력란을 확인해 주세요.',
  CHECK_MANAGER_KOREAN_NAME: '관리자명(한글) 입력란을 확인해 주세요.',
  CHECK_MANAGER_ENGLISH_NAME: '관리자명(영어) 입력란을 확인해 주세요.',
  CHECK_OWNER_PHONE_NUMBER: '소유자 휴대전화 입력란을 확인해 주세요.',
  CHECK_MANAGER_PHONE_NUMBER: '관리자 휴대전화 입력란을 확인해 주세요.',
  NO_OWNER_EMAIL_CODE: '소유자 이메일 인증코드를 요청해 주세요.',
  NO_MANAGER_EMAIL_CODE: '관리자 이메일 인증코드를 요청해 주세요.',
  CHECK_OWNER_EMAIL_CODE: '소유자 이메일 인증을 완료해 주세요.',
  CHECK_MANAGER_EMAIL_CODE: '관리자 이메일 인증을 완료해 주세요.',
  CHECK_OWNER_VERIFICATION_CODE_INPUT: '소유자 이메일 인증코드를 다시 한 번 확인해 주세요.',
  CHECK_MANAGER_VERIFICATION_CODE_INPUT: '관리자 이메일 인증코드를 다시 한 번 확인해 주세요.',
  CHECK_OWNER_ADDRESS: '소유자 주소를 입력해 주세요.',
  CHECK_MANAGER_ADDRESS: '관리자 주소를 입력해 주세요.',
  CHECK_OWNER_ROAD_ADDRESS: '소유자 주소를 도로명주소로 검색한 뒤 등록해 주세요.',
  CHECK_MANAGER_ROAD_ADDRESS: '관리자 주소를 도로명주소로 검색한 뒤 등록해 주세요.',
  CHECK_ADDITIONAL_ADDRESS: '상세 주소까지 모두 입력해 주세요.',
  CHECK_AGREEMENTS: '이용약관 동의 후 결제를 진행하실 수 있습니다.',
  CHECK_NO_CANCEL_EXTEND_POLICY: '도메인 기간 연장 취소 정책에 동의해 주셔야 합니다.',
};
